<template>
 <div class="content">
   <app-cells position="between">
     <app-cells :indent="false">
       <h1 class="title title--theme">Трансфер</h1>
<!--       <router-link v-if="userIsAdmin" :to="{ name: 'members-stat' }" class="btn btn&#45;&#45;link">-->
<!--         <img src="@/assets/img/sidebar/stat.svg" alt="Stat icon">-->
<!--         <span>Статистика</span>-->
<!--       </router-link>-->
     </app-cells>
     <v-select
       v-if="userIsAdmin"
       :reduce="item => item.id"
       :options="delegation_options"
       :filterable="true"
       :clearable="false"
       v-model="user_delegation_id"
       @input="onSetDelegation"
       label="name"
       placeholder="Выберите делегацию"
       class="select select--minw"
     >
       <template #open-indicator>
         <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
           <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
           <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
         </svg>
       </template>
       <template #option="option">
         <div class="select__item d-center">{{ option.name }}</div>
       </template>
       <template #selected-option="option">
         <div class="selected d-center">{{ option.name }}</div>
       </template>
       <template #no-options>Ничего не найдено</template>
     </v-select>
   </app-cells>
   <app-cells v-if="!userIsAdmin && !delegation_settings.transfers_done" position="between">
     <router-link  :to="{ name: 'transfer-create' }" class="btn btn--link btn--link-icon">
       <span>Добавить трансфер</span>
     </router-link>
     <app-button
       v-if="!delegation_settings.transfers_done"
       @click="onShowModal"
       size="small"
     >
       Отправить на проверку
     </app-button>
   </app-cells>
   <app-cells v-if="userIsAdmin" position="start">
     <app-checkbox
       v-model="delegation_settings.transfers_done"
       @change="onChangeDelegationSettings({transfers_done: delegation_settings.transfers_done}, false)"
       label="Делегация сформирована"
     />
   </app-cells>
   <app-cells v-if="!userIsAdmin && delegation_settings.transfers_done" position="start">
     <div class="notice notice--yellow">
       <div class="notice__title">
         <img  src="@/assets/img/info-icon-yellow.svg" alt="Info icon">
         <span>Информация</span>
       </div>
       <p class="notice__text">
         Ваша делегация находится на проверке регламентной службой. Добавление новых трансферов - недоступно.
       </p>
     </div>
   </app-cells>
   <h2 class="title title--medium title--indent">Прибытие на фестиваль</h2>
   <app-table-head
     @update-limiter="onUpdateLimiter"
     :count="table.items.length"
     :page="pagination.page"
     :all="pagination.count"
   />
   <v-client-table
     @row-click="onRowClick"
     :data="table.items"
     :columns="table.columns"
     :options="table.options"
     ref="tableDefault"
     class="table-default table-default--dynamic"
   >
     <template #date="props">
       {{ formatDate(props.row.date) }}
     </template>
     <template #time="props">
       {{ formatTime(props.row.time) }}
     </template>
     <template #number="props">
       {{ props.row.number ? props.row.number : '-' }}
     </template>
   </v-client-table>
   <paginate
     v-if="pagination.pages > 1"
     v-model="pagination.page"
     :page-count="pagination.pages"
     :clickHandler="onClickPagination"
     :prev-text="'<'"
     :next-text="'>'"
     :container-class="'pagination'"
     :page-class="'pagination__item'"
     :page-link-class="'pagination__link'"
     :prev-class="'pagination__prev'"
     :next-class="'pagination__next'"
     :next-link-class="'pagination__link-next'"
     :prev-link-class="'pagination__link-prev'"
   />
   <app-cells position="start">
     <h2 class="title title--medium">Убытие с фестиваля</h2>
   </app-cells>
   <app-table-head
     @update-limiter="onUpdateLimiterOut"
     :count="table_out.items.length"
     :page="pagination_out.page"
     :all="pagination_out.count"
   />
   <v-client-table
     @row-click="onRowClick"
     :data="table_out.items"
     :columns="table_out.columns"
     :options="table_out.options"
     ref="tableDefaultOut"
     class="table-default table-default--dynamic"
   >
     <template #date="props">
       {{ formatDate(props.row.date) }}
     </template>
     <template #time="props">
       {{ formatTime(props.row.time) }}
     </template>
     <template #number="props">
       {{ props.row.number ? props.row.number : '-' }}
     </template>
   </v-client-table>
   <paginate
     v-if="pagination_out.pages > 1"
     v-model="pagination_out.page"
     :page-count="pagination_out.pages"
     :clickHandler="onClickPaginationOut"
     :prev-text="'<'"
     :next-text="'>'"
     :container-class="'pagination'"
     :page-class="'pagination__item'"
     :page-link-class="'pagination__link'"
     :prev-class="'pagination__prev'"
     :next-class="'pagination__next'"
     :next-link-class="'pagination__link-next'"
     :prev-link-class="'pagination__link-prev'"
   />
   <modal
     name="send-review-modal"
     :width="750"
     :height="'auto'"
     :scrollable="true"
     :adaptive="true"
   >
     <div class="modal">
       <div class="modal__title modal__title--center-black">Подтверждение действия</div>
       <p class="modal__text">
         Вы уверены, что хотите отправить список трансферов на проверку и завершить формирование
         делегации? Вы не сможете добавлять новые трансферы. Это действие сможет отменить только регламентная служба
       </p>
       <app-cells position="center">
         <app-button size="small" @click="onChangeDelegationSettings({transfers_done: true}, true)" ref="reviewSubmit">Да</app-button>
         <app-button size="small" @click="onHideModal">Нет</app-button>
       </app-cells>
     </div>
   </modal>
 </div>
</template>

<script>
import {getTransferList, getDelegations, patchUser, getDelegationSettings, patchDelegationSettings} from '@/http'
import { dateFormatting } from '@/helpers'

export default {
  name: 'TransferList',
  data() {
    return {
      table: {
        items: [],
        columns: ['date', 'time', 'pariticipants_count', 'transport_type.name', 'number'],
        options: {
          headings: {
            'date': 'Дата',
            'time': 'Время',
            'pariticipants_count': 'Количество человек',
            'transport_type.name': 'Тип транспорта',
            'number': 'Номер рейса',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Вы еще не добавили ни одного трансфера',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          // rowClassCallback: row => `table-default__row table-default__row--${row.status.code}`,
          rowClassCallback: row => 'table-default__row',
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
      },
      table_limit: 20,
      table_out: {
        items: [],
        columns: ['date', 'time', 'pariticipants_count', 'transport_type.name', 'number'],
        options: {
          headings: {
            'date': 'Дата',
            'time': 'Время',
            'pariticipants_count': 'Количество человек',
            'transport_type.name': 'Тип транспорта',
            'number': 'Номер рейса',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Вы еще не добавили ни одного трансфера',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          // rowClassCallback: row => `table-default__row table-default__row--${row.status.code}`,
          rowClassCallback: row => 'table-default__row',
        },
      },
      pagination_out: {
        pages: 1,
        page: 1,
        count: 0,
      },
      table_limit_out: 20,
      delegation_settings: {},
      delegation_options: [],
      user_delegation_id: null,
    }
  },
  computed: {
    userIsAdmin() {
      return this.$store.state.user_role === 'admin'
    },
  },
  created() {
    this.fetchItems()
    this.fetchDelegationsSettings()
    setTimeout(() => {
      if (this.userIsAdmin) {
        getDelegations()
          .then(response => {
            this.delegation_options = response.data
            this.user_delegation_id = this.$store.state.delegation
            if (this.$route.params.delegation_id) {
              this.user_delegation_id = this.$route.params.delegation_id
              this.onSetDelegation()
            }
          })
      }
    }, 200)
  },
  methods: {
    onShowModal() {
      this.$modal.show('send-review-modal')
    },
    onHideModal() {
      this.$modal.hide('send-review-modal')
    },
    onChangeDelegationSettings(data, withModal) {
      if (withModal) this.$refs.reviewSubmit.preload = true
      patchDelegationSettings(data)
        .finally(() => {
          this.fetchDelegationsSettings()
          this.fetchItems()
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Настройки делегации изменены'
          })
          if (withModal) {
            this.$refs.reviewSubmit.preload = false
            this.onHideModal()
          }
        })
    },
    fetchDelegationsSettings() {
      getDelegationSettings()
        .then(response => {
          this.delegation_settings = response.data
        })
    },
    formatDate(date) {
      return dateFormatting(date, 'iso-to-normal-small')
    },
    formatTime(time) {
      return time.slice(0, 5)
    },
    fetchItems() {
      getTransferList({page: this.pagination.page, page_size: this.table_limit, transfer_type: true})
        .then(response => {
          this.table.items = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
        })
      getTransferList({page: this.pagination_out.page, page_size: this.table_limit_out, transfer_type: false})
        .then(response => {
          this.table_out.items = response.data.results
          this.pagination_out.pages = response.data.pages
          this.pagination_out.count = response.data.count
        })
    },
    onRowClick(row) {
      this.$router.push({ name: 'transfer-detail', params: { id: row.row.pk } })
    },
    onUpdateLimiter(data) {
      this.table_limit = data
      this.pagination.page = 1
      this.$refs.tableDefault.setLimit(this.table_limit)
      this.fetchItems()
    },
    onClickPagination(page) {
      this.pagination.page = page
      this.fetchItems()
    },
    onUpdateLimiterOut(data) {
      this.table_limit_out = data
      this.pagination_out.page = 1
      this.$refs.tableDefaultOut.setLimit(this.table_limit_out)
      this.fetchItems()
    },
    onClickPaginationOut(page) {
      this.pagination_out.page = page
      this.fetchItems()
    },
    onSetDelegation() {
      this.$store.commit('setDelegation', this.user_delegation_id)
      patchUser({ delegation: this.user_delegation_id })
        .then(response => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Делегация успешно изменена'
          })
          this.fetchItems()
          this.$store.commit('setDelegationHash', response.data.delegation_hash)
        })
    },
  }
}
</script>

<style scoped>

</style>
